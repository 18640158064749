import React, { useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useTranslation } from "react-i18next";
import Container from "@material-ui/core/Container";
import MetaMaskOnboarding from '@metamask/onboarding';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { Loading, MostSubmitButton, } from "./components/MostComponents";
import { bexplorerLink, GoToHomePage, } from "./components/OpusComponents";
import useStyles from "./components/useStyles";
import { Header } from "./Header";
import { Footer } from "./Footer";
import MyAxios, { check_response } from "./MyAxios";
import { prettyJson, now } from "./Utils";
import { MMdecrypt } from "./Crypto";
import { BCdocs } from "./BCdocs";
import { BCenroll } from "./BCenroll";
import { BCnewdossier } from "./BCnewdossier";
import { BCopenKey } from "./BCopenKey";
import { BCinvite } from "./BCinvite";
import { BCsell } from "./BCsell";
import { BCcheck } from "./BCcheck";

// https://awantoch.medium.com/how-to-connect-web3-js-to-metamask-in-2020-fee2b2edf58a
const Web3 = require("web3")

// xxx eliminato parametro force_reload="true" in GuardedRoute
//     ma cosi' anche arrivando da menu viene mantenuto contenuto vecchio
//     Come azzerare contenuto? (necessario rimbalzare su un'altra pagina!)
//     In alternativa si usa force_reload="true" ma non si puo' usare
//     appAlert() perché altrimenti con l'alert si resetta tutta la pagina

export const BC = (props) => {
  const history = useHistory();
  const messagesEndRef = useRef(null)
  const reteKovan = 0;
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  const { userInfo } = useGlobalHook('userStore');
  //console.log("userInfo", userInfo);

  // Can be a string as well. Need to ensure each key-value pair ends with ;
  const spinnerCss = css`
    display: block;
    margin: 0 auto;
  `;
  const [esiti, setEsiti] = useState("");
  const [startup, setStartup] = useState(true);
  const [loading, setLoading] = useState(false);

  const [connectButtonText, setConnectButtonText] = React.useState(t('Clicca per installare MetaMask'));
  const [connectButtonDisabled, setConnectButtonDisabled] = React.useState(false);
  const [insertButtonDisabled, setInsertButtonDisabled] = React.useState(true);
  const [showBack, setShowBack] = React.useState(false);
  const [showGoHome, setShowGoHome] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const [account, setAccount] = React.useState("");
  const [chainId, setChainId] = React.useState('');
  const [badBCId, setBadBCId] = React.useState(true);
  const onboarding = React.useRef();

  const [dossierInfo, setDossierInfo] = React.useState(null);
  const [docs, setDocs] = useState([]); //elenco documenti relativi a dossier_id

  function addEsiti(s) {
      s = now() + " - " + s;
      setEsiti(prevCount => prevCount==="" ? s : prevCount + "<br>" + s)
  }

  function showChainId(id) {
    let dec;
    try {
        dec = ""+parseInt(id,16);
    } catch (err) {
        dec = "";
    }
    if (reteKovan)
        setBadBCId(dec !== "42");
    else
        setBadBCId(dec !== process.env.REACT_APP_BCID);
    //return dec+" ("+id+")";
    return dec
  }

  const scrollToBottom = () => {
    if(esiti !== "" && messagesEndRef.current)
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const connectButtonOnClick = useCallback(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      setConnectButtonDisabled(true);
      setInsertButtonDisabled(false);
      setConnectButtonText("Connessione in corso");
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((newAccounts) => {
            setAccounts(newAccounts);
        })
        .catch(err => {
            console.error("eth_requestAccounts",err);
            // {code: 4001, message: "User rejected the request."}
            if(!(err.code && err.code===4001)) {
                appAlert("eth_requestAccounts: "+JSON.stringify(err));
            }
            setConnectButtonDisabled(false);
            setInsertButtonDisabled(true);
            setConnectButtonText(t('Connetti la pagina a MetaMask'));
        });
    } else {
      onboarding.current.startOnboarding();
    }
  }, [appAlert,t])

  React.useEffect(scrollToBottom, [esiti]);

  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      console.log("BC: handleNewAccounts",newAccounts);
      setAccounts(newAccounts);
    }
    function handleNewChain(newChainId) {
      console.log("BC: handleNewChain",newChainId);
      setChainId(showChainId(newChainId));
    }
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.web3 = new Web3(window.ethereum);
      // apre MM all'apertura della pagina senza click esplicito: da documentazione non si dovrebbe fare...
      if(!connectButtonDisabled)
        connectButtonOnClick()
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleNewChain);
      return () => {
        window.ethereum.removeListener('accountsChanged', handleNewAccounts);
        window.ethereum.removeListener('chainChanged', handleNewChain);
      };
    }
  }, [connectButtonOnClick,connectButtonDisabled]);

  React.useEffect(() => {
    if ((props.mode==="newdossierBC" || props.mode==="docsBC" || props.mode==="inviteBC" || props.mode==="sellBC" || props.mode==="checkBC") && !dossierInfo) {
        let jdata = { dossier_id: props.match.params.dossierId };
        MyAxios.post("documents", jdata)
          .then((response) => {
            response = check_response(response);
            setStartup(false)
            if (response.success) {
                setDossierInfo(response.dossierInfo);
                setDocs(response.rows);
            } else {
              console.error(response);
              appAlert(response.error);
              setInsertButtonDisabled(true)
            }
          })
          .catch(function (error) {
            console.error(error);
            appAlert(error.message?error.message:JSON.stringify(error));
            setInsertButtonDisabled(true)
            setStartup(false)
          })
    } else {
        setStartup(false)
    }
  }, [props.mode,props.match.params.dossierId,dossierInfo,appAlert]);

  React.useEffect(() => {
    const getChainId = async function () {
      console.log("getChainId");
      try {
        const chainId = await window.ethereum.request({
          method: 'eth_chainId',
        })
        console.log("got chainId",chainId);
        setChainId(showChainId(chainId));
      } catch (err) {
        console.error("eth_chainId",err)
      }
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        console.log("BC: accounts changed, got account",accounts[0]);
        setAccount(accounts[0]);
        setConnectButtonText(t('Connesso a MetaMask'));
        setConnectButtonDisabled(true);
        setInsertButtonDisabled(false);
        onboarding.current.stopOnboarding();
        getChainId();
      } else {
        console.log("BC: accounts changed, NO account");
        setAccount("");
        setConnectButtonText(t('Connetti la pagina a MetaMask'));
        setConnectButtonDisabled(false);
        setInsertButtonDisabled(true);
        setChainId('');
      }
    }
  }, [accounts,t]);

  const addAddressToEsiti = (text,address) => {
       address = address.toLowerCase()
       addEsiti(text+": "+address+" "+bexplorerLink(address))
  }

  const addConctractAddressToEsiti = (contractAddress) => {
       addAddressToEsiti(t("Indirizzo del contratto"),contractAddress)
  }

  const addOperationOkHashToEsiti = (hash) => {
       addEsiti(t("Operazione terminata con successo in blockchain")+bexplorerLink(null,hash))
  }

  const openKeyFunction = async (okHandler) => {
    setInsertButtonDisabled(true);
    setLoading(true);
    try {
        addEsiti(t("Apertura della chiave per potere decifrare i documenti"))
        addEsiti(t("Necessario premere bottone 'Decifra' in MetaMask"))
        const from = window.web3.utils.toChecksumAddress(account)
        const ret = await MMdecrypt(userInfo.ecies_privatekey,from)
        setLoading(false);
        const err = ret.err
        if (err) {
            setInsertButtonDisabled(false);
            if(err.code && err.code===4001) {
                addEsiti(t("Permesso rifiutato"));
                return;
            }
            appAlert('errore: '+JSON.stringify(err));
            return;
        }
        const privateKey64 = ret.cleartext
        sessionStorage.setItem('eciesPrivateKey',privateKey64)
        if(okHandler) {
            addEsiti(t("Chiave aperta"));
            okHandler()
            return
        }
        setShowBack(true)
        addEsiti(t("Chiave aperta"));
    } catch(error) {
        console.error("openKeyFunction error",error);
        addEsiti(error.message?error.message:JSON.stringify(error));
        setInsertButtonDisabled(false);
        setLoading(false);
    }
  }

  const goBack = () => {
    history.goBack()
  }

  const goHome = () => {
    let url = "/loginok"
    history.push(url);
  }
    
  let title;
  let error = null;
  if(props.mode==="enrollBC") {
      title = t("AssociaIndirizzo");
      if (userInfo.bcaddress_in_bc && esiti==="") {
          console.log("bcaddress already registered");
          error = userInfo.bcaddress+'<br>'+t('indirizzo già registrato correttamente');
      }
  } else if(props.mode==="sellBC") {
      if(!props.location.state || !props.location.state.acquirente) {
          console.error("no props.location.state.acquirente")
          return <GoToHomePage />
      }
      title = t("Cessione all'utente ")+props.location.state.acquirente
      if(dossierInfo && dossierInfo.username !== userInfo.username) {
          error = "dossierInfo.username "+dossierInfo.username+" !== userInfo.username "+userInfo.username
          console.log(error)
      }
  } else if(props.mode==="inviteBC") {
      if(!props.location.state || !props.location.state.ospite) {
          console.error("no props.location.state.ospite")
          return <GoToHomePage />
      }
      title = t("Invito all'utente ")+props.location.state.ospite
      if(dossierInfo && dossierInfo.username !== userInfo.username) {
          error = "dossierInfo.username "+dossierInfo.username+" !== userInfo.username "+userInfo.username
          console.log(error)
      }
  } else if(props.mode==="checkBC") {
      title = t("Controllo dati crittografati");
  } else if(props.mode==="docsBC") {
      title = t("Registrazione Documenti in BlockChain");
      if (!userInfo.bcaddress_in_bc) {
          console.log("no userInfo.bcaddress_in_bc");
          error = t('Associa prima il tuo indirizzo BlockChain');
      }
      if (!(userInfo.email && userInfo.email !== "")) {
          console.log("no useridentity");
          error = t('Aggiungi prima le tue informazioni anagrafiche');
      }
      if(dossierInfo && dossierInfo.username !== userInfo.username) {
          error = "dossierInfo.username "+dossierInfo.username+" !== userInfo.username "+userInfo.username
          console.log(error)
      }
      if(dossierInfo && !dossierInfo.contract_initialized) {
          error = t("Dossier non ancora registrato in BlockChain")
          console.log(error)
      }
  } else if(props.mode==="newdossierBC") {
      title = t("Registrazione Dossier Opera in BlockChain");
      if (!userInfo.bcaddress_in_bc) {
          console.log("no userInfo.bcaddress_in_bc");
          error = t('Associa prima il tuo indirizzo BlockChain');
      }
      if (!(userInfo.email && userInfo.email !== "")) {
          console.log("no useridentity");
          error = t('Aggiungi prima le tue informazioni anagrafiche');
      }
      if(dossierInfo && dossierInfo.username !== userInfo.username) {
          error = "dossierInfo.username "+dossierInfo.username+" !== userInfo.username "+userInfo.username
          console.log(error)
      }
      if(dossierInfo && dossierInfo.contract_initialized) {
          error = t("Dossier già registrato in BlockChain")
          console.log(error)
      }
  } else if(props.mode==="openKey") {
      title = t("Apertura chiave di lettura");
      if (sessionStorage.getItem('eciesPrivateKey') && esiti==="")
        error = t("Chiave già aperta")
      if (!userInfo.ecies_privatekey)
        error = t('Associa prima il tuo indirizzo BlockChain');
  } else 
        return (
            <div>
              <Header />
              <h1> Bad mode {props.mode} </h1>
              <Footer />
            </div>
        );
  if (error)
      return (
        <div>
          <Header />
          <h1> {title} </h1>
          <h2 className="blackColor" dangerouslySetInnerHTML={{__html: error}} />
          <Footer />
        </div>
      )
  return (
    <div>
      <Header />
      <h1>{title}</h1>
      <Container component="main" maxWidth="md">
        { startup ?
            <Loading />
        :
        <div className={classes.root}>
          { props.mode==="enrollBC" ? (
            <table className="ethTable">
                <caption>{t("Parametri di connessione RPC personalizzata")}</caption>
                <tbody>
                <tr><th>{t("Nome rete")}</th><td>{t("Rete di test Rebus")}</td></tr>
                <tr><th>URL RPC</th><td>{process.env.REACT_APP_ETH} </td></tr>
                <tr><th>Blockchain ID</th><td>{process.env.REACT_APP_BCID}</td></tr>
                </tbody>
            </table>
          ) : null }
          { props.mode==="openKey" ? (
            <p className="blackColor"> 
                Per accedere ai documenti e per invitare ospiti<br />è necessario aprire la propria chiave di crittografia
            </p>
          ) : null }
          <MostSubmitButton type="button" onClick={connectButtonOnClick} disabled={connectButtonDisabled} label={t(connectButtonText)} />
          <table className="ethTable">
                <caption>{t("Configurazione attuale MetaMask")}</caption>
                <tbody>
                <tr>
                    <th>{t("Rete")}</th>
                    <td className={(reteKovan ? "reteKovan" : (badBCId ? "badValue" : ""))}>{chainId}</td>
                </tr>
                {chainId !== "" && badBCId ? (
                        <tr>
                            <th></th>
                            <td className="badValue">
                                {t("MetaMask è collegato a una rete diversa dalla rete Rebus")}
                                <br />
                                {t("Connetti MetaMask alla rete ")+process.env.REACT_APP_BCID}
                        </td>
                        </tr>
                ) : null}
                { props.mode==="enrollBC" ? (
                    <tr>
                        <th>{t("bcaddress")}</th>
                        <td>{account}</td>
                    </tr>
                ) : /* props.mode: newdossierBC docsBC openKey inviteBC sellBC checkBC */ (
                    <tr>
                        <th>{t("bcaddress")}</th>
                        <td className={(account===userInfo.bcaddress?"":"badValue")}>{account}</td>
                    </tr>
                ) }
                    { userInfo.bcaddress!==null && account!==userInfo.bcaddress && account!=="" && userInfo.bcaddress!=="" ? (
                        <tr>
                            <th></th>
                            <td className="badValue">{t("In MetaMask è attivo un indirizzo diverso da quello configurato precedentemente "+userInfo.bcaddress)}</td>
                        </tr>
                    ) : null}
                </tbody>
          </table>

          { props.mode==="enrollBC" ? (
                <BCenroll
                    disabled1={insertButtonDisabled || badBCId}
                    badBCId={badBCId} account={account} setShowGoHome={setShowGoHome} setInsertButtonDisabled={setInsertButtonDisabled} setLoading={setLoading} addEsiti={addEsiti}
                />
          ) : null }
          { props.mode==="newdossierBC" || props.mode==="docsBC" || props.mode==="inviteBC" || props.mode==="sellBC" || props.mode==="checkBC" ? (
                <div>
                    { dossierInfo ? (
                        <table className="ethTable">
                        <caption>Opera</caption>
                        <tbody>
                            <tr>
                                <td className="w200">Nome</td>
                                <td>{dossierInfo.nomeopera}</td>
                            </tr>
                            <tr>
                                <td className="w200">Autore</td>
                                <td>{dossierInfo.autoredetail.nome} {dossierInfo.autoredetail.cognome} ({dossierInfo.autoredetail.nomeinarte}) </td>
                            </tr>
                        </tbody>
                        </table>
                    ) : null }
                </div>
          ) : null }
          { props.mode==="newdossierBC" ? (
                <BCnewdossier
                    disabled={insertButtonDisabled || badBCId || account!==userInfo.bcaddress}
                    dossierId={props.match.params.dossierId}
                    account={account} dossierInfo={dossierInfo} addOperationOkHashToEsiti={addOperationOkHashToEsiti} setShowBack={setShowBack} setInsertButtonDisabled={setInsertButtonDisabled} addConctractAddressToEsiti={addConctractAddressToEsiti} setLoading={setLoading} addEsiti={addEsiti}
                />
          ) : null }
          { props.mode==="checkBC" ? (
                <React.Fragment>
                <div>
                    { dossierInfo ? (
                        <table className="ethTable">
                        <caption>NFT</caption>
                        <tbody>
                            <tr>
                                <td className="w200">TokenId</td>
                                <td>{dossierInfo.token_id}</td>
                            </tr>
                            <tr>
                                <td className="w200">URI</td>
                                <td>{dossierInfo.tokenURI}</td>
                            </tr>
                            <tr>
                                <td className="w200">Metadati</td>
                                <td dangerouslySetInnerHTML={{__html: prettyJson(dossierInfo.tokenURI_content,1)}} />
                            </tr>
                        </tbody>
                        </table>
                    ) : null }
                </div>
                <BCcheck
                    disabled={insertButtonDisabled || badBCId || account!==userInfo.bcaddress}
                    openKeyFunction={openKeyFunction}
                    username={userInfo.username}
                    addAddressToEsiti={addAddressToEsiti} account={account} addConctractAddressToEsiti={addConctractAddressToEsiti} dossierInfo={dossierInfo} addEsiti={addEsiti} setInsertButtonDisabled={setInsertButtonDisabled} setLoading={setLoading}
                />
                </React.Fragment>
          ) : null }
          { props.mode==="docsBC" ? (
                <BCdocs 
                    disabled={insertButtonDisabled || badBCId || account!==userInfo.bcaddress} 
                    dossierInfo={dossierInfo} docs={docs} setLoading={setLoading} addEsiti={addEsiti} setInsertButtonDisabled={setInsertButtonDisabled} account={account} addOperationOkHashToEsiti={addOperationOkHashToEsiti} setShowBack={setShowBack} 
                />
          ) : null }
          { props.mode==="openKey" ? (
                <BCopenKey
                    disabled={insertButtonDisabled || badBCId || account!==userInfo.bcaddress}
                    label={t("Apri la chiave")}
                    openKeyFunction={openKeyFunction}
                />
          ) : null }
          { props.mode==="sellBC" ? (
                <BCsell
                    disabled={insertButtonDisabled || badBCId || account!==userInfo.bcaddress}
                    dossierId={props.match.params.dossierId}
                    acquirente={props.location.state.acquirente}
                    acquirente_bcaddress={props.location.state.acquirente_bcaddress}
                    acquirente_pub_key64={props.location.state.acquirente_pub_key64}
                    docv={props.location.state.docv}
                    openKeyFunction={openKeyFunction} dossierInfo={dossierInfo} setLoading={setLoading} addEsiti={addEsiti} setInsertButtonDisabled={setInsertButtonDisabled} account={account} addOperationOkHashToEsiti={addOperationOkHashToEsiti}
                />
          ) : null }
          { props.mode==="inviteBC" ? (
                <BCinvite
                    disabled={insertButtonDisabled || badBCId || account!==userInfo.bcaddress}
                    dossierId={props.match.params.dossierId}
                    ospite={props.location.state.ospite}
                    giorni={props.location.state.giorni}
                    ospite_bcaddress={props.location.state.ospite_bcaddress}
                    ospite_pub_key64={props.location.state.ospite_pub_key64}
                    docv={props.location.state.docv}
                    openKeyFunction={openKeyFunction} dossierInfo={dossierInfo} setLoading={setLoading} addEsiti={addEsiti} setInsertButtonDisabled={setInsertButtonDisabled} account={account} addOperationOkHashToEsiti={addOperationOkHashToEsiti}
                />
          ) : null }

          { loading ? (
            <b>{t("Operazione su BlockChain in corso")}</b>
          ) : null }
          <PropagateLoader color="#AAAA00" css={spinnerCss} loading={loading} />
          { esiti !== "" ? (
            <table className="ethTable esitiTable">
                <caption>{t("Esito")}</caption>
                <tbody>
                <tr><td className="esiti" dangerouslySetInnerHTML={{__html: esiti}} /></tr>
                </tbody>
            </table>
          ) : null }
          { showBack ? (
            <div>
                <MostSubmitButton 
                    type="button" onClick={goBack}
                    label={t("BackPage")} />
            </div>
          ) : null }
          { showGoHome ? (
            <div>
                <MostSubmitButton 
                    type="button" onClick={goHome}
                    label={t("Vai alla pagina principale")} />
            </div>
          ) : null }
        </div>
        }
        <div ref={messagesEndRef} />
      </Container>
      <Footer />
    </div>
    );
};

import React, { useState, Suspense } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { GlobalHooksProvider, createGlobalHook } from '@devhammed/use-global-hook'
import GuardedRoute from './GuardedRoute';
import { CertificationRequest } from './CertificationRequest';
import { Dossier } from './Dossier';
import { DossierDetail } from './DossierDetail';
import { Home } from './Home';
import { Login, Logout } from './Login';
import { LoginOk } from './LoginOk';
//import { MMLogin} from './MMLogin';
import { ForgottenPasswd } from './auth/ForgottenPasswd';
import { NewDocument } from './NewDocument';
import { NewDossier } from './NewDossier';
import { NftInfo } from './NftInfo';
import { Reload } from './Reload';
import { Register } from './Register';
import { BC } from './BC';
import { Identity } from './auth/Identity';
import { ChangePasswd } from './auth/ChangePasswd';
import { Timeout, NoMatch } from './components/MostComponents';
import AlertDialog from './AlertDialog';
// import { ProvideAuth } from "./use-auth";
//import logo from './Smartag.png';
import './App.css';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    {/* <img src={logo} className="App-logo" alt="logo" /> */}
    <div>Loading...</div>
  </div>
);

const alertStore = createGlobalHook('alertStore', () => {
  const [alert1, setAlert1] = useState(false);
  const [content, setContent] = React.useState("")
  return { alert1, setAlert1, content, setContent }
})
const alert2Store = createGlobalHook('alert2Store', () => {
  const [alert2, setAlert2] = useState(false);
  const [content2, setContent2] = React.useState("")
  const [onClose2, setOnClose2] = React.useState(null)
  return { alert2, setAlert2, content2, setContent2, onClose2, setOnClose2 }
})
const confirmStore = createGlobalHook('confirmStore', () => {
  const [confirm1, setConfirm1] = useState(false);
  const [cContent, setCContent] = React.useState("")
  const [onConfirm, setOnConfirm] = React.useState(null)
  return { confirm1, setConfirm1, cContent, setCContent, onConfirm, setOnConfirm }
})
const userStore = createGlobalHook('userStore', () => {
  const [userInfo, setUserInfo] = useState({});
  return { userInfo, setUserInfo }
})

export default function App() {

  const timeout = 15 * 60 * 1000
  const [idleTimeout, setIdleTimeout] = useState(false);
  const handleOnIdle = () => {
    setIdleTimeout(true)
  }
  const resetIdleTimeout = () => {
    console.log("resetIdleTimeout")
    setIdleTimeout(false)
    reset()
  }

  const {
    reset
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  })

  if(window.ethereum)
    window.ethereum.autoRefreshOnNetworkChange = false;

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#E1E002',
        contrastText: '#000000',
      },
      // secondary : usare button variant="outlined" 
      // per fare button con colore di sfondo, usare variant="contained", main: colore sfondo, contrastText: colore scritta
      secondary: {
        main: '#777700'
      },
      /*
      background: {
        default: '#999999',
      },
      */
    },
  });

            // <GuardedRoute path="/mmlogin">  <MMLogin />  </GuardedRoute>
  return (
  <ThemeProvider theme={theme}>
   <CssBaseline />
   <GlobalHooksProvider hooks={[ alertStore, alert2Store, confirmStore, userStore ]}>
    <Suspense fallback={<Loader />}>
    <Timeout idleTimeout={idleTimeout} />
      <div className="App">
        <Router>
          <Switch>
            <Route path="/login">  <Login resetIdleTimeout={resetIdleTimeout} />  </Route>
            <Route path="/logout">  <Logout />  </Route>
            <Route path="/register">  <Register resetIdleTimeout={resetIdleTimeout} />  </Route>
            <Route path="/forgot_password">  <ForgottenPasswd />  </Route>
            <Route path="/opusdocs" render={() => (window.location = "/OpusDocs")} />
            <Route path="/opusdocs0" render={() => (window.open("/OpusDocs", "_blank"))} />
            <GuardedRoute path='/cert_request' key="cert_request" component={CertificationRequest} />
            <GuardedRoute path='/dossier' key="dossier" component={Dossier} />
            <GuardedRoute path='/newdossier' key="newdossier" component={NewDossier} />
            <GuardedRoute path='/dossierdetail' key="dossierdetail" component={DossierDetail} />
            <GuardedRoute path='/enrollBC' key="enrollBC" component={BC} />
            <GuardedRoute path='/openKey' key="openKey" component={BC} />
            <GuardedRoute path='/newdossierBC/:dossierId' key="newdossierBC" component={BC} />
            <GuardedRoute path='/docsBC/:dossierId' key="docsBC" component={BC} />
            <GuardedRoute path='/inviteBC/:dossierId' key="inviteBC" component={BC} />
            <GuardedRoute path='/sellBC/:dossierId' key="sellBC" component={BC} />
            <GuardedRoute path='/checkBC/:dossierId' key="checkBC" component={BC} />
            <GuardedRoute path='/identity' key="identity" component={Identity} />
            <GuardedRoute path='/changepasswd' key="changepasswd" component={ChangePasswd} />
            <GuardedRoute path='/identityM' key="identityM" component={Identity} />
            <GuardedRoute path='/loginok' key="loginok" component={LoginOk} />
            <GuardedRoute path='/newdocument' key="newdocument" component={NewDocument} />
            <GuardedRoute path='/nftinfo/:dossierId' key="nftinfo" component={NftInfo} />
            <GuardedRoute path='/reload/:path' key="reload" component={Reload} />
            <Route exact path="/">  <Home />  </Route> 
            <Route> <NoMatch /> </Route>
          </Switch>
        </Router>
      </div>
    </Suspense>
    <AlertDialog name="alert"/>
    <AlertDialog name="alert2"/>
    <AlertDialog name="confirm"/>
   </GlobalHooksProvider>
  </ThemeProvider>
  );
}

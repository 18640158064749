import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useForm } from "react-hook-form";
import MyAxios, { check_response } from "./MyAxios";
import { MostSubmitButton, MostSelect } from "./components/MostComponents";
import { bcLog, bcLogParam, waitTransactionReceipt, getDossierContractStruct, getEvent } from "./BCutils";

export const BCnewdossier = (props) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const { control, handleSubmit } = useForm();
  const { userInfo } = useGlobalHook('userStore');
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])
  const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
  function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
  }
  let newdossierBC_hashipfs = null;
  const availkit = userInfo.available_kits
  let warningContinuazione = false;
  let kitid_options = []
  for (let i in availkit) {
    if (availkit[i].dossier_id  === props.dossierId) {
        warningContinuazione = true;
        kitid_options = [{label: availkit[i].idkit, value: availkit[i].idkit}]
        break;
    }
    if(!availkit[i].dossier_id)
        kitid_options.push({label: availkit[i].idkit, value: availkit[i].idkit});
  }

  const mint = async (contract,contractStruct,uri,idkit,dossierId) => {
    console.log("mint","uri",uri,"idkit",idkit,"dossierId",dossierId)
    if (!uri) {
        props.setLoading(false)
        appAlert("ERRORE: missing uri")
        return
    }
    try {
        props.addEsiti(t("Mint NFT in BlockChain"))
        props.addEsiti(t("Necessario premere bottone 'Conferma' in MetaMask"))
        const from = window.web3.utils.toChecksumAddress(props.account)
        const to = from
        bcLog("safeMint(to,uri,from) to="+to+" uri="+uri+" from="+from)
        contract.methods.safeMint(
            to,uri
        ).send({
            from: from
        },
        (err,res) => {
            if(err) {
                props.setLoading(false)
                if(err.code && err.code===4001) {
                    props.addEsiti(t("Conferma interazione contratto rifiutata"))
                    return
                }
                console.error("safeMint error ",err)
                props.addEsiti(t("Errore")+": "+JSON.stringify(err))
            } else {
                bcLog("got hash "+bcLogParam(res))
                const hash = res
                props.addEsiti(t("Hash")+": "+hash)
                // https://ethereum.stackexchange.com/questions/66454/how-to-get-events-emitted-by-a-transaction-with-web3-js
                waitTransactionReceipt(hash,(err,res) => {
                    if(err) {
                        props.setLoading(false)
                        props.addEsiti(t("Errore")+": "+JSON.stringify(err))
                    } else {
                        props.addOperationOkHashToEsiti(hash)
                        // tokenId string (BigNumber == uint256 : numero su 32 bytes, max value decimale ha 78 cifre)
                        const ev = getEvent(contractStruct,res,'Transfer')
                        // from: "0x0000000000000000000000000000000000000000" (new mint)
                        // to
                        // tokenId
                        if(ev && ev.tokenId) {
                            console.log("event",ev)
                            const token_id = ev.tokenId
                            console.log("token_id",token_id)
                            props.addEsiti("NFT tokenId: "+token_id)
                            kitFinish(t("Registrazione dell'operazione nel database"),{ phase: 2, dossier_id: dossierId, idkit: idkit, token_id: token_id, tx_hash: hash },true,(err,res) => {
                                console.log("kitFinish fase 2 handler",err,res)
                                if (err) {
                                    props.addEsiti(t("Operazione terminata con successo in blockchain, ma fallita registrazione nel database, contattare l'assistenza"))
                                } else {
                                    props.setShowBack(true)
                                    props.addEsiti(t("Operazione terminata con successo"))
                                }
                                props.setLoading(false)
                            })
                        } else {
                            const err = 'Event not found'
                            console.error(err,res)
                            props.addEsiti(t("Errore")+": "+err)
                        }
                    }
                })
                props.addEsiti(t("Operazione BlockChain in corso, attendere la terminazione")+"...")
            }
        })
    } catch(error) {
        console.error("mint error",error)
        props.addEsiti(error.message?error.message:JSON.stringify(error))
        props.setLoading(false)
    }
  }

  function kitFinish(desc,vals,showEsiti,handler) {
    console.log("kitFinish",desc,vals);
    desc += ": ";
    MyAxios.post("kit_finish", vals)
      .then((response) => {
        response = check_response(response);
        // alert(JSON.stringify(response));
        console.log("kitFinish response",response);
        if (response.success) {
          if(showEsiti)
            props.addEsiti(desc+"OK")
          if(handler) {
            handler(null,response);
          }
        } else {
          console.error(response);
          props.addEsiti(desc+t("Errore")+": "+response.error)
          if(handler)
            handler(response,null);
        }
      })
      .catch(function (error) {
        console.error(error);
        props.addEsiti(desc+error.message?error.message:JSON.stringify(error));
        if(handler)
            handler(error,null);
      })
      .then(function () {
        // always executed
      });
  }

  /*
  const continuazione = (idkit) => {
    props.setInsertButtonDisabled(true);
    props.setLoading(true);
    const dossierId = props.dossierId
    console.log("continuazione",dossierId,idkit)
    dossierInitialize(props.dossierInfo.bccontract_address,
        props.dossierInfo.json_hashipfs,props.dossierInfo.json_aes_crypted_key,props.dossierInfo.json_clear_hash,
        props.dossierInfo.fruibilitadossier_id,dossierId,idkit);
  }
  */

  const kitTransaction = async (idkit,dossierId) => {
    const contractStruct = await getDossierContractStruct(props.addEsiti);
    if(!contractStruct) {
        return;
    }
    const contract = await new window.web3.eth.Contract(contractStruct.abi,contractStruct.contractAddress)
    if(!contract) {
        const err = "null contract"
        console.error(err)
        props.addEsiti(t("Errore")+": "+err)
        return;
    }
    props.setInsertButtonDisabled(true);
    props.setLoading(true);
    kitFinish(t("Registrazione dell'hash"),{ phase: 1, dossier_id: dossierId, idkit: idkit },false,(err,res) => {
      console.log("kitFinish fase 1 handler",err,res)
      if(err) {
          console.error("kitFinish fase 1 fallita")
          props.addEsiti(t("Errore: kitFinish fase 1 fallita, contattare l'assistenza"))
      } else {
        if(res && res.hashipfs) {
          newdossierBC_hashipfs = res.hashipfs
          console.log("kitFinish fase 1: got newdossierBC_hashipfs",newdossierBC_hashipfs)
          const uri = process.env.REACT_APP_IPFS + "/ipfs/" + newdossierBC_hashipfs
          mint(contract,contractStruct,uri,idkit,dossierId)
        } else {
          console.error("kitFinish fase 1: no hashipfs")
          props.addEsiti(t("Errore: kitFinish fase 1 no hashipfs, contattare l'assistenza"))
        }
     }
   })
  }

  const newdossierBC_onSubmit = (vals) => {
    if(vals.kitid === "") {
      appAlert(t("Seleziona il Kit Id utilizzato per firmare l'opera"));
      return;
    }
    if(warningContinuazione) {
      appAlert("Attenzione, processo con kit "+vals.kitid.value+" precedentemente interrotto non correttamente")
      return;
    }
    const text = t("Stai associando permanentemente l’opera «")+props.dossierInfo.nomeopera+"» "+
        t(" di ")+props.dossierInfo.autoredetail.nome+" "+props.dossierInfo.autoredetail.cognome+" ("+props.dossierInfo.autoredetail.nomeinarte+") "+
        t(" alla firma con kit ")+"«"+vals.kitid.label+"».<br>"+
        t("Se procedi non potrai tornare indietro.")+"<br>"+
        t("Confermi?")
    appConfirm(text,() => {
        console.log(vals)
        newdossierBC_hashipfs = null;
        kitTransaction(vals.kitid.value,props.dossierId)
    })
  };

  return (
        <div>
            {/* warningContinuazione ? (
                <p className="badValue"> {t("Attenzione")+": "+t("continuazione processo precedentemente interrotto")} </p>
            ) : null */}
            { kitid_options ? (
                kitid_options.length ? (
                    <form onSubmit={handleSubmit(newdossierBC_onSubmit)}>

                        <MostSelect control={control} name="kitid" options={kitid_options} placeholder={t("kit id")} />
                        <MostSubmitButton 
                            disabled={props.disabled}
                            label={t("Registra in BlockChain")} />
                    </form>
                ) : (
                    <div>
                        <span className="error"> Nessun kit disponibile </span>
                        <MostSubmitButton type="button" onClick={() => history.push("/cert_request")} label={t("Acquista un kit")} />
                    </div>
                )
            ) : null }
</div>
)}

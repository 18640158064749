import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { FileUpload } from "./components/FileUpload";
import { DocData } from "./components/DocData";
import useStyles from "./components/useStyles";
import MyAxios, { check_response } from "./MyAxios";
import Grid from "@material-ui/core/Grid";
import { useGlobalHook } from "@devhammed/use-global-hook";
import { GoToHomePage } from "./components/OpusComponents";

export const NftInfo = (props) => {
  const history = useHistory();
  let react_router_location = useLocation();
  let dossier_id
  if (props.location.state)
    dossier_id = props.location.state.dossier_id;
  else
    dossier_id = react_router_location.pathname.split("/")[2];

  const { t } = useTranslation(["translation", "documento"]);
  const [loading, setLoading] = useState(false);
  const spinnerCss = css`
    display: block;
    margin: 0 auto;
  `;
  const [disabledButs, setDisabledButs] = useState(false)
  const [uploadInfo, setUploadInfo] = useState(null);
  const [dossierInfo, setDossierInfo] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [docs, setDocs] = useState([]);
  const classes = useStyles();
  const { control, register, handleSubmit, errors, setValue } = useForm();
  const { setAlert1, setContent } = useGlobalHook("alertStore");
  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])
  const { setConfirm1, setCContent, setOnConfirm } = useGlobalHook('confirmStore');
  function appConfirm(text,okHandler) {
      setCContent(text);
      setOnConfirm(() => x => {
        okHandler();
      });
      setConfirm1(true);
  }

  useEffect(() => {
    if(!dossier_id)
        return
    let jdata = { dossier_id: dossier_id };
    let url = "/nft_info/"+dossier_id
    MyAxios.get(url).then((response) => {
      response = check_response(response);
      if (!response.success) {
        appAlert(response.error)
        setDisabledButs(true)
        return
      }
      //console.log("/documents response:" + JSON.stringify(response));
      let data = response;
      setDossierInfo(data.nft_info);
      setTokenId(data.token_id);
      setDocs(data.rows)
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    });
  }, [appAlert,dossier_id]);

  const onSubmit = (vals) => {
    console.log("Entro onSubmit: " + JSON.stringify(vals));
    if (!uploadInfo) {
        appAlert("File non scelto")
        return
    }
    vals["dossieropera_id"] = dossier_id;

    console.log("onSubmit uploadInfo: " + uploadInfo);
    console.log("onSubmit: " + JSON.stringify(vals));
    //alert("onSubmit: " + JSON.stringify(vals));

    const sep = "|"
    const att = vals.author.trim()+sep+vals.title.trim()+sep+vals.tassonomiadocumenti.value
    let versione = 0
    for (let i in docs) {
        console.log(docs[i])
        if(docs[i].author+sep+docs[i].title+sep+docs[i].tassonomiadocumenti_id === att) {
            if(docs[i].versione > versione) {
                versione = docs[i].versione
            }
        }
    }
    if(versione) {
        versione++
        const text = "Documento già presente. Inserimento della versione n. "+versione+" ?"
        appConfirm(text,() => {
            doSubmit(vals,versione)
        })
    } else {
        doSubmit(vals,1)
    }
  }

  const doSubmit = (vals,versione) => {
    vals["versione"] = versione
    console.log("doSubmit", vals)
    let formData = new FormData();

    formData.append("upload", uploadInfo[0]);
    formData.append("author", vals.author);
    formData.append("dossieropera_id", dossier_id);
    formData.append("title", vals.title);
    formData.append("versione", vals.versione);
    formData.append(
      "accessibilitadocumenti",
      vals.accessibilitadocumenti.value
    );
    formData.append("tassonomiadocumenti", vals.tassonomiadocumenti.value);

    setDisabledButs(true)
    setLoading(true)
    MyAxios.post("/newdoc2", formData, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=ZZZZZZZZZZZZZZZZZZZ",
      },
    })
      .then((response) => {
        response = check_response(response);
        // alert(JSON.stringify(response));
        //console.log(response);
        if (response.success) {
          let url = "/dossierdetail/" + dossier_id;
          history.push(url);
        } else {
          console.error(response);
          appAlert(response.error);
          setDisabledButs(false)
          setLoading(false)
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        appAlert(error.message ? error.message : JSON.stringify(error));
        setDisabledButs(false)
        setLoading(false)
      })
  };

  // manca parametro alla url
  if (!dossier_id) {
    return <GoToHomePage />
  }

  return (
    <div>
      <Header />
        {dossierInfo ? (
          <Container component="main" maxWidth="md">
      <h1> {t("Informazioni su NFT ") + tokenId} </h1>
                <pre>{JSON.stringify(dossierInfo,null,"\t")}</pre>
          </Container>
            ) : null }
      <Footer />
    </div>
  );
};

import React, { useState, useMemo, useEffect, useCallback } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import { useGlobalHook } from "@devhammed/use-global-hook";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MyAxios, { check_response } from "./MyAxios";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Table } from "./Table";
import { Loading, MostSelect, MostTextField, MostButton2, MostSubmitButton, Check, WarningIcon } from "./components/MostComponents";
import { GoToHomePage, Riservato, BexplorerLink } from "./components/OpusComponents";
import IpfsDialog from "./components/IpfsDialog";
import { dmy_hms, prettyJson } from "./Utils";
//import { provaE } from "./Crypto";

let dossier_id = "";
export const DossierDetail = (props) => {
  const history = useHistory();
  const [showjson, setShowjson] = useState(false);
  const [disabledButs, setDisabledButs] = useState(false);
  const [sellOrInviteMode, setSellOrInviteMode] = useState(null);
  const [controparteUsername, setControparteUsername] = useState(null);
  const [dossierInfo, setDossierInfo] = useState(null);
  const [isVideo, setIsVideo] = useState(null);
  const [docs, setDocs] = useState([]); //elenco documenti relativi a dossier_id
  const [doc_bc_sync, setDoc_bc_sync] = useState(true);
  const { userInfo } = useGlobalHook("userStore");

  const { t } = useTranslation(["translation", "documento", "dossier"]);
  const { control, register, handleSubmit, errors } = useForm();

  const { setAlert1, setContent } = useGlobalHook("alertStore");
  const appAlert = useCallback(
    (text) => {
      setContent(text);
      setAlert1(true);
    },
    [setContent, setAlert1],
  );
  const giorniOptions = [
    { label: "15 giorni", value: 15 },
    { label: "30 giorni", value: 30 },
  ];

  let react_router_location = useLocation();
  //console.log("props: " + JSON.stringify(props.location));
  if (props.location.state) {
    //console.log("Dentro  props: " + JSON.stringify(props.location));
    dossier_id = props.location.state.dossier_id;
    //console.log("DENTRO dossier_id: " + dossier_id);
  } else {
    //console.log("uselocation: " + JSON.stringify(react_router_location));
    dossier_id = react_router_location.pathname.split("/")[2];
  }
  //console.log("dossier_id",dossier_id)

  useEffect(() => {
    if (!dossier_id) return;
    let jdata = { dossier_id: dossier_id };
    MyAxios.post("/documents", jdata)
      .then((response) => {
        response = check_response(response);
        if (!response.success) {
          appAlert(response.error);
          return;
        }
        //console.log("/documents response:" + JSON.stringify(response));
        const data = response;
        const dossierInfo = data.dossierInfo;
        setIsVideo(dossierInfo.isVideo);
        // nazionalizzazione: attenzione cambiamo struttura ricevuta via ipc
        if (dossierInfo.bccontract_address) dossierInfo.bccontract_address = dossierInfo.bccontract_address.toLowerCase();
        dossierInfo.tiposupporto_id = t("dossier:tiposupporto_id." + dossierInfo.tiposupporto_id);
        dossierInfo.fruibilitadossier_id = t("dossier:fruibilitadossier_id." + dossierInfo.fruibilitadossierdetail.code);
        if (dossierInfo.luogooperadetail) {
          if (dossierInfo.luogooperadetail.tipoluogo_id) dossierInfo.luogooperadetail.tipoluogo_id = t("dossier:tipoluogo_id." + dossierInfo.luogooperadetail.tipoluogo_id);
          else dossierInfo.luogooperadetail.tipoluogo_id = "";
        }
        if (dossierInfo.statusopera_id) dossierInfo.statusopera_id = t("dossier:statusopera_id." + dossierInfo.statusopera_id);
        if (dossierInfo.fruibilitaopera_id) dossierInfo.fruibilitaopera_id = t("dossier:fruibilitaopera_id." + dossierInfo.fruibilitaopera_id);
        setDossierInfo(dossierInfo);
        setDocs(data.rows);
      })
      .catch(function (error) {
        console.error(error);
        appAlert(error.message ? error.message : JSON.stringify(error));
      });
  }, [appAlert, t]);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: t("documento:Documento"),
        columns: [
          {
            Header: t("documento:Accessibilità"),
            accessor: "accessibilitadocumentidetail.description",
          },
          {
            Header: t("documento:author"),
            accessor: "author",
          },
          {
            Header: t("documento:title"),
            accessor: "title",
          },
          {
            Header: t("documento:Tipo Documento"),
            accessor: "tassonomiadocumentidetail.description",
          },
        ],
      },
      {
        Header: t("dossier:Dettaglio"),
        columns: [
          {
            Header: t("documento:Documento"),
            accessor: "hashipfs",
            Cell: ({ cell: { value }, row: { original } }) => <IpfsDialog disabledButs={disabledButs} setDisabledButs={setDisabledButs} info={original} />,
          },
          {
            Header: t("documento:version"),
            accessor: "versione",
          },
          {
            Header: t("documento:filename"),
            accessor: "filename",
          },
          {
            Header: t("documento:filesize"),
            accessor: "filesize",
          },
          {
            Header: t("documento:mimetype"),
            accessor: "mimetype",
            Cell: ({ cell: { value }, row: { original } }) => {
              value = value.replace(/\./g, "");
              return <span>{t("dossier:mimetype." + value)}</span>;
            },
          },
          {
            Header: t("documento:Ora inserimento"),
            accessor: "ora_inserimento",
            Cell: ({ cell: { value }, row: { original } }) => {
              const s = dmy_hms(new Date(value), 1);
              return <span>{s}</span>;
            },
          },
          {
            Header: t("documento:Ora modifica"),
            accessor: "datamodifica",
            Cell: ({ cell: { value }, row: { original } }) => {
              let s = "";
              if (value) s = dmy_hms(new Date(value), 1);
              return <span>{s}</span>;
            },
          },
          {
            Header: t("documento:BC aggiornata"),
            accessor: "data_sync_bc",
            Cell: ({ cell: { value }, row: { original } }) => {
              // controllare data scrittura in BC con data modifica DB
              if (value && (!original.datamodifica || value > original.datamodifica)) return <Check good={true} />;
              // Cannot update a component (`Documents`) while rendering a different component (`Cell`)
              setTimeout(() => {
                setDoc_bc_sync(false);
              }, 100);
              return <Check good={false} />;
            },
          },
        ],
      },
    ];
    return cols;
  }, [t, disabledButs]);

  const nuovoDoc = () => {
    console.log("nuovoDoc dossier_id: " + dossier_id);
    history.push({
      pathname: "/newdocument",
      state: { dossier_id: dossier_id },
    });
  };

  const documents2BC = () => {
    console.log("documents2BC dossier_id: " + dossier_id);
    let url = "/docsBC/" + dossier_id;
    history.push(url);
  };

  const sell = () => {
    setSellOrInviteMode("sell");
  };

  const invite = () => {
    setSellOrInviteMode("invite");
  };

  const onSubmitSellOrInvite = (vals) => {
    console.log("onSubmitSellOrInvite: " + JSON.stringify(vals));
    if (sellOrInviteMode === "sell") doSell(vals);
    else doInvite(vals);
  };

  const doSell = (vals) => {
    vals.mode = "check";
    vals.dossierid = dossier_id;
    console.log("doSell: " + JSON.stringify(vals));
    setDisabledButs(true);
    MyAxios.post("/dossiersell", vals)
      .then((response) => {
        response = check_response(response);
        if (response.success) {
          history.push({
            pathname: "/sellBC/" + dossier_id,
            state: {
              acquirente: vals.acquirente,
              acquirente_pub_key64: response.pub_key64,
              acquirente_bcaddress: response.bcaddress,
              docv: response.docv,
            },
          });
        } else {
          console.error(response);
          appAlert(response.error);
          setDisabledButs(false);
        }
      })
      .catch(function (error) {
        console.error(error);
        appAlert(error.message ? error.message : JSON.stringify(error));
        setDisabledButs(false);
      });
  };

  const clone_create = () => {
    let vals = {};
    vals.dossier_id = dossier_id;
    vals.action = "create_clones";
    MyAxios.post("/clone_create", vals)
      .then((response) => {
        alert("Generazione cloni in corso");
        history.push("/dossier");
      })
      .catch(function (error) {
        console.error(error);
        appAlert(error.message ? error.message : JSON.stringify(error));
        setDisabledButs(false);
      });
  };

  const clone_mint = () => {
    let vals = {};
    vals.dossier_id = dossier_id;
    vals.action = "mint_clones";
    MyAxios.post("/clone_create", vals)
      .then((response) => {
        alert("Generazione cloni in corso");
        history.push("/dossier");
      })
      .catch(function (error) {
        console.error(error);
        appAlert(error.message ? error.message : JSON.stringify(error));
        setDisabledButs(false);
      });
  };

  const doInvite = (vals) => {
    vals.mode = "check";
    vals.giorni = vals.giorni.value;
    vals.dossierid = dossier_id;
    console.log("doInvite: " + JSON.stringify(vals));
    setDisabledButs(true);
    MyAxios.post("/dossierinvite", vals)
      .then((response) => {
        response = check_response(response);
        if (response.success) {
          history.push({
            pathname: "/inviteBC/" + dossier_id,
            state: {
              ospite: vals.ospite,
              giorni: vals.giorni,
              ospite_pub_key64: response.pub_key64,
              ospite_bcaddress: response.bcaddress,
              docv: response.docv,
            },
          });
        } else {
          console.error(response);
          appAlert(response.error);
          setDisabledButs(false);
        }
      })
      .catch(function (error) {
        console.error(error);
        appAlert(error.message ? error.message : JSON.stringify(error));
        setDisabledButs(false);
      });
  };

  /*
  function showUri(token_id,tokenURI) {
    MyAxios.get(tokenURI, {
      "baseURL": "/",
    })
    .then(async (response) => {
        let text = 
            "NFT TokenId: "+token_id+"<br/>"+
            "NFT URI: "+tokenURI+"<br/>"+
            "<br/>"
        const json = response.data
        text += prettyJson(json,1)
        appAlert(text)
    })
    .catch(function (error) {
        console.error(error)
        appAlert(error)
    })
  }
  */

  // manca parametro alla url
  if (!dossier_id) {
    return <GoToHomePage />;
  }

  //console.log("DOCS",docs)
  //console.log("doc_bc_sync",doc_bc_sync)
  return (
    <div>
      <Header />
      {userInfo.application == "techne" ? (
        <h1>{t("dossier:DossierDetail")}</h1>
      ) : (
        <h1>{t("dossier:ImageDetail")}</h1>
      )}
      {dossierInfo ? (
        <div>
          <Container component="main" maxWidth="md">
            <table className="ethTable dossierDettaglioTable gray">
              <tbody>
                <tr>
                  <th>{t("dossier:Immagine")}</th>
                  <td>
                    {isVideo ? (
                      <video controls autoPlay width="400" >
                      <source src={dossierInfo.image_uri} type="video/mp4" />
                      </video>
                    ) : (
                      <img src={dossierInfo.image_uri} width={400} />
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{t("dossier:Tiratura")}</th>
                  <td>{dossierInfo.tiratura}</td>
                </tr>
                <tr>
                  <th>{t("documento:Id")}</th>
                  <td>{dossierInfo.id}</td>
                </tr>
                <tr>
                  <th>{t("documento:Proprietario")}</th>
                  <td>
                    {dossierInfo.useridentitydetail ? (
                      <span>
                        {dossierInfo.useridentitydetail?.nome + " " + dossierInfo.useridentitydetail?.cognome + " (" + dossierInfo.username + ")"}
                        <Riservato reserved={dossierInfo.riservatezzaproprietario} />
                      </span>
                    ) : dossierInfo.username ? (
                      <span>
                        {dossierInfo.username}
                        <Riservato reserved={dossierInfo.riservatezzaproprietario} />
                      </span>
                    ) : (
                      <span>
                        <Riservato reserved={true} />
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{t("dossier:nomeopera")}</th>
                  <td>{dossierInfo.nomeopera}</td>
                </tr>

                {userInfo.application == "elivilla" ? (
                  <>
                    <tr>
                      <th>{t("dossier:celebrity")}</th>
                      <td>{dossierInfo.celebrity}</td>
                    </tr>
                    <tr>
                      <th>{t("dossier:year")}</th>
                      <td>{dossierInfo.year}</td>
                    </tr>
                  </>
                ) : null}
                {userInfo.application != "techne" ? (
                <tr>
                  <th>{t("dossier:mint_info")}</th>
                  <td>{JSON.stringify(dossierInfo.mint_info)}</td>
                </tr>
                ) : null}
                <tr>
                  <th>{t("dossier:autore")}</th>
                  <td>
                    {dossierInfo.autoredetail.nome} {dossierInfo.autoredetail.cognome} ({dossierInfo.autoredetail.nomeinarte}){" "}
                  </td>
                </tr>
                <tr>
                  <th>{t("dossier:TipoOpera")}</th>
                  <td>{dossierInfo.tipooperadetail ? dossierInfo.tipooperadetail.description : ""}</td>
                </tr>
                {userInfo.application == "techne" ? (
                  <>
                    <tr>
                      <th>{t("dossier:TipoSupporto")}</th>
                      <td>{dossierInfo.tiposupporto_id}</td>
                    </tr>
                    <tr>
                      <th>{t("dossier:LuogoOpera")}</th>
                      <td>
                        {dossierInfo.luogooperadetail
                          ? dossierInfo.luogooperadetail.citta + " " + dossierInfo.luogooperadetail.indirizzo + " " + dossierInfo.luogooperadetail.nazione + " (" + dossierInfo.luogooperadetail.tipoluogo_id + ")"
                          : ""}
                        <Riservato reserved={dossierInfo.riservatezzaluogo} />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("dossier:StatoOpera")}</th>
                      <td>
                        {dossierInfo.statusopera_id}
                        <Riservato reserved={dossierInfo.riservatezzastatus} />
                      </td>
                    </tr>
                    <tr>
                      <th>{t("dossier:FruibilitaDossier")}</th>
                      <td>{dossierInfo.fruibilitadossier_id}</td>
                    </tr>
                    <tr>
                      <th>{t("dossier:FruibilitaOpera")}</th>
                      <td>{dossierInfo.fruibilitaopera_id}</td>
                    </tr>
                  </>
                ) : null}
                <tr>
                  <th className="vertalignTop">{t("documento:In BC")}</th>
                  <td>
                    {dossierInfo.contract_initialized ? (
                      <div>
                        <Check good={true} />
                        <br />
                        {t("dossier:Indirizzo contratto")}: {dossierInfo.bccontract_address} <BexplorerLink address={dossierInfo.bccontract_address} />
                        <br />
                        {t("dossier:Hash transazione")}: {dossierInfo.bccontract_hash} <BexplorerLink tx_hash={dossierInfo.bccontract_hash} />
                        <br />
                        NFT TokenId: {dossierInfo.token_id}
                        <br />
                        NFT URI: {dossierInfo.tokenURI}
                        <br />
                        {/*
                            <span className="cursorPointer"><Link underline="always" color="secondary" onClick={() => showUri(dossierInfo.token_id,dossierInfo.tokenURI)}>Mostra</Link></span>
                            */}
                        {showjson ? (
                          <div>
                            <div dangerouslySetInnerHTML={{ __html: prettyJson(dossierInfo.tokenURI_content, 1) }} />
                            {dossierInfo.fruibilitadossierdetail.code === "DOSSIER_FRUIBILITY_COMPLETELY_PRIVATE" ? (
                              <span className="cursorPointer">
                                <Link underline="always" color="secondary" onClick={() => history.push("/checkBC/" + dossierInfo.id)}>
                                  Mostra in chiaro
                                </Link>
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <span className="cursorPointer">
                            <Link underline="always" color="secondary" onClick={() => setShowjson(true)}>
                              {t("dossier:Mostra")}
                            </Link>
                          </span>
                        )}
                        {/*
                            <MostButton2 className="bcenter" onClick={() => provaE()} label="xxx PROVE xxx" />
                            */}
                      </div>
                    ) : dossierInfo.bccontract_address ? (
                      <span>
                        <WarningIcon /> {t("documento:Operazione non completata")}
                      </span>
                    ) : (
                      <Check good={false} />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            {!sellOrInviteMode && dossierInfo.username === userInfo.username ? (
              !dossierInfo.contract_initialized ? (
                <React.Fragment>
                  <div className="MuiContainer-root MuiContainer-maxWidthXs">
                    <MostSubmitButton type="button" disabled={disabledButs} onClick={() => history.push("/newdossierBC/" + dossierInfo.id)} label={t("documento:Registra il Dossier Opera in BlockChain")} />
                  </div>
                </React.Fragment>
              ) : (
                  userInfo.application == "techne" ? (
                <div className="MuiContainer-root MuiContainer-maxWidthXs">
                  {dossierInfo.fruibilitadossierdetail.code !== "DOSSIER_FRUIBILITY_COMPLETELY_PUBLIC" ? (
                    <div>
                      <MostSubmitButton type="button" disabled={disabledButs} onClick={invite} label={t("documento:Invita un ospite a visualizzare il tuo Dossier Opera")} />
                    </div>
                  ) : null}
                  <MostSubmitButton type="button" disabled={disabledButs} onClick={sell} label={t("documento:Cedi la tua opera ad un acquirente")} />
                </div>
              ): null)
            ) : null}
            {userInfo.application != "techne" ? (
              <>
                <div className="MuiContainer-root MuiContainer-maxWidthXs">
                  <MostSubmitButton type="button" disabled={disabledButs} onClick={clone_create} label={t("dossier:DoClone")} />
                </div>
                <div className="MuiContainer-root MuiContainer-maxWidthXs">
                  <MostSubmitButton type="button" disabled={disabledButs} onClick={clone_mint} label={t("dossier:DoMint")} />
                </div>
              </>
            ) : null}
          </Container>

          {userInfo.application != "techne" ? (
            null
          ) : (
          !sellOrInviteMode ? (
            <div>
              <h2>{t("Documenti")} </h2>
              <div className="blackColor margin20 gray">{docs.length ? <Table columns={columns} data={docs} /> : t("dossier:NoDocument") }</div>
              {dossierInfo && userInfo && dossierInfo.username === userInfo.username ? (
                <div>
                  <div className="MuiContainer-root MuiContainer-maxWidthXs">
                    <MostSubmitButton type="button" disabled={disabledButs} onClick={nuovoDoc} label={t("dossier:NuovoDocumento")} />
                    {/* se dossier gia' in BC e se almeno 1 doc non gia' in BC */}
                    {dossierInfo.contract_initialized && !doc_bc_sync ? <MostSubmitButton type="button" disabled={disabledButs} onClick={documents2BC} label={t("dossier:Registra i documenti in BlockChain")} /> : null}
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <h2>{sellOrInviteMode === "sell" ? t("documento:Cessione opera") : t("documento:Invito ospite")} </h2>
              <div className="blackColor">
                {sellOrInviteMode === "sell" ? t("documento:Acquirente_gia_registrato?") : t("documento:Ospite_gia_registrato?")}
                <br />
                <MostButton2 variab={controparteUsername} variab_value={true} onClick={() => setControparteUsername(true)} label={t("dossier:Si")} className="margin-sg-10" />{" "}
                <MostButton2 variab={controparteUsername} variab_value={false} onClick={() => setControparteUsername(false)} label={t("dossier:No")} className="margin-sg-10" />
                {controparteUsername === true ? (
                  <div>
                    <Container component="main" maxWidth="md">
                      <form onSubmit={handleSubmit(onSubmitSellOrInvite)} noValidate>
                        {sellOrInviteMode === "sell" ? (
                          <Grid item xs={12}>
                            <MostTextField name="acquirente" required={true} label={t("documento:username acquirente")} register={register({ required: true })} />
                            {errors.acquirente && <span className="badValue">{t("campo obbligatorio")}</span>}
                          </Grid>
                        ) : (
                          <React.Fragment>
                            <Grid item xs={12}>
                              <MostTextField name="ospite" required={true} label={t("dossier:username ospite")} register={register({ required: true })} />
                              {errors.ospite && <span className="badValue">{t("campo obbligatorio")}</span>}
                            </Grid>
                            <Grid item xs={12}>
                              <MostSelect control={control} name="giorni" options={giorniOptions} rules={{ required: true }} placeholder={t("dossier:durata della condivisione") + " *"} />
                              {errors.giorni && <span className="badValue">{t("campo obbligatorio")}</span>}
                            </Grid>
                          </React.Fragment>
                        )}
                        <MostSubmitButton disabled={disabledButs} label={t("Conferma")} />
                      </form>
                    </Container>
                  </div>
                ) : controparteUsername === false ? (
                  <div>Invitalo a registrarsi su {process.env.REACT_APP_SERVER} e chiedigli di comunicarti il suo username</div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </div>
  );
};


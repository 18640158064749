import React from "react";
import { Header } from './Header';
import { Footer } from './Footer';
import { useTranslation, Trans } from 'react-i18next';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import { useGlobalHook } from '@devhammed/use-global-hook'
import { useHistory } from "react-router-dom";
import { MostSubmitButton, WarningIcon, Check } from "./components/MostComponents";
import logo from './DT-alpha.png';

export const LoginOk = () => {
    const { userInfo } = useGlobalHook('userStore');
    const { t } = useTranslation();
    const history = useHistory();
    const mykit = userInfo.my_kits
    const availkit = userInfo.available_kits
    return (
  <div>
    <Header />
    <div>
        <Container component="main" maxWidth="md">
        <img src={logo} className="xxxxApp-logo small-logo" alt="logo" />
        <Typography component="h1" variant="h5">
          <Trans i18nKey="techneTitle" />
        </Typography>
        <h1>{t("Benvenuto")} {userInfo.username}</h1>
        <table className="loginokTab">
        <tbody>
        { userInfo.email ? (
                userInfo.email_verificata ? (
                    <tr> 
                        <td><Check good={true} /></td> 
                        <td>{t("DatiOK")}</td> 
                        <td> </td>
                    </tr>
                ) : (
                    <tr> 
                        <td><WarningIcon /></td> 
                        <td>{t("Indirizzo non verificato")}</td> 
                        <td>
                            <MostSubmitButton type="button" className="b_loginok" onClick={() => history.push("/identityM")} label={t("Controlla i tuoi dati")} />
                        </td>
                    </tr>
                )
            ) : (
                <tr> 
                    <td><WarningIcon /> </td> 
                    <td>{t("DatiKO")}</td> 
                    <td>
                        <MostSubmitButton type="button" className="b_loginok" onClick={() => history.push("/identity")} label={t("Registra i tuoi dati")} />
                    </td>
                </tr>
            )}
        { userInfo.bcaddress_in_bc ? (
                <tr> 
                    <td><Check good={true} /></td> 
                    <td>{t("BCAddressOK")}</td> 
                    <td> </td>
                </tr>
            ) : (
                <tr> 
                    <td><WarningIcon /> </td> 
                    <td>{t("BCAddressKO")}</td> 
                    <td>
                        <MostSubmitButton type="button" className="b_loginok" onClick={() => history.push("/enrollBC")} label={t("Associa il tuo indirizzo BlockChain")} />
                    </td>
                </tr>
            )}
        { userInfo.role === "ROLE_OSPITE" ?  (
                <React.Fragment>
                <tr> 
                    <td><WarningIcon /> </td> 
                    <td>{t("role")} {t(userInfo.role)} </td> 
                    <td> </td> 
                </tr>
                </React.Fragment>
            ) : (
                <React.Fragment>
                <tr> 
                    <td><Check good={true} /> </td> 
                    <td>{t("role")} {t(userInfo.role)} </td> 
                    <td> </td> 
                </tr>
                { mykit && mykit.length ? (
                    <tr> 
                        <td><Check good={true} /> </td> 
                        <td>
                            <Trans i18nKey="numeroKitDisponibili" count={availkit.length} />
                            {" "}
                            ( <Trans i18nKey="numeroKitAcquistati" count={mykit.length} /> )
                        </td> 
                        <td> </td> 
                    </tr>
                ) : (
                    null
                )}
                </React.Fragment>
            )}
        </tbody>
        </table>
        { userInfo.email_verificata ? (
                <MostSubmitButton type="button" onClick={() => history.push("/cert_request")} label={t("Richiesta di certificazione")} />
            ) : (
                null
            )}
        <MostSubmitButton type="button" onClick={() => history.push("/dossier")} label={userInfo.role !== "ROLE_OSPITE" ? t("Gestisci le tue opere") : t("Visualizza le opere")} />
        </Container>
    </div>
    <Footer />
  </div>
    );
};
